@font-face {
font-family: '__satoshi_33e41a';
src: url(/premium/_next/static/media/867bce6efedfde96-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100 1000;
}@font-face {font-family: '__satoshi_Fallback_33e41a';src: local("Arial");ascent-override: 92.36%;descent-override: 21.95%;line-gap-override: 9.14%;size-adjust: 109.35%
}.__className_33e41a {font-family: '__satoshi_33e41a', '__satoshi_Fallback_33e41a'
}.__variable_33e41a {--font-satoshi: '__satoshi_33e41a', '__satoshi_Fallback_33e41a'
}

